import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';
import Masonry from 'react-masonry-css';
import Modal from '../components/Modal';
import { useNavigate } from 'react-router-dom';
import Model from '../components/model';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import loader from '../Assets/images/movie-reel.svg'
import { AiFillCloseCircle, AiFillStar, AiOutlineDownload, AiOutlineStar } from 'react-icons/ai';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

export const FvtPhotos = () => {

    const [photosUrls, setPhotosUrls] = useState([])
    const [photosPath, setPhotosPath] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentIndex, setCurentIndex] = useState(0)
    const [photos, setPhotos] = useState([])
    const [imgKey, setImgKey] = useState(null)
    const [slideIndex, setSlideIndex] = React.useState(-1);
    const [isFavourite, setIsFavourite] = useState(false);
    const buttonClass = isFavourite ? '' : 'bounce';

    const navigate = useNavigate()


    const location = useLocation();
    const targetMedia = location.state;

    const { addToFvtPhotos, Link, remFvtPhoto } = UserAuth()
    const targetLink = Link && Link[targetMedia.target]

    const openModal = (key) => {
        setIsModalOpen(true);
        setImgKey(key)
    };

    const closeModal = () => {
        setIsModalOpen(false)
        // const propsToPass = {
        //     target: targetMedia,
        //   };
        // navigate('/media/fvtphotos',{ state: propsToPass })
    }



    // wasabi credentials
    const wasabi_key_id = process.env.REACT_APP_WASABI_ACCESS_KEY_ID
    const wasabi_key = process.env.REACT_APP_WASABI_ACCESS_KEY
    const bucketName = "digialbum";
    const region = "ap-southeast-1"; // Update with your bucket's region
    const endpoint = "https://s3.ap-southeast-1.wasabisys.com"; // Update with your Wasabi endpoint
    // const folderName = "Personal/Birthday/Photos/"; 

    // wasabi credentials
    const s3 = new AWS.S3({
        region: region,
        endpoint: endpoint,
        credentials: {
            accessKeyId: wasabi_key_id,
            secretAccessKey: wasabi_key
        }
    });
    AWS.config.update({
        region: region,
        accessKeyId: wasabi_key_id,
        secretAccessKey: wasabi_key,
    });




    const getPhotosUrls = async (link) => {

        console.log("Links to", link)
        const promise = link.map((path, index) => {

            console.log("path", path)
            console.log("indexdata", index)
            const params = {
                Bucket: bucketName,
                Key: path,
            }

            const targetPhotoHd = Link.userType === 'Standard' ? path : path.replace("T_", "")
            const slideParams = { Bucket: bucketName, Key: targetPhotoHd };

            setPhotosPath((prev) => [...prev, path])

            const signedUrl = s3.getSignedUrl('getObject', params);
            const signedUrlFull = s3.getSignedUrl('getObject', slideParams);

            const parts = path.split('/')

            // Find the last dot (.) in the string
            const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
            let resultString
            if (lastDotIndex !== -1) {
                // Extract the part of the string before the last dot
                resultString = parts[parts.length - 1].substring(0, lastDotIndex);
            } else {
                // If there is no dot in the string, keep the original string
                resultString = parts[parts.length - 1];
            }

            let obj = {
                id: index,
                url: signedUrl,
                downloadUrl: signedUrlFull,
                Key: path,
                isFavouriteSlide: 1,
                blobUrl: "",
                imageName: resultString,
                src: signedUrlFull,
                title: resultString,
            }

            photos.push(obj)

            setPhotos(photos);

            return signedUrl

        })

        // Wait for all signed URL promises to resolve
        // Promise.all(promise)
        //     .then((urls) => {
        //         setPhotosUrls(urls);
        //     })
        //     .catch((error) => {
        //         console.error('Error generating signed URLs:', error.message);
        //     });
    }


    const handleFavourite = (targetPhoto) => {

        console.log(slideIndex, photos[slideIndex].isFavouriteSlide, photos[slideIndex].Key)

        if (photos[slideIndex].isFavouriteSlide === 1) {
            photos[slideIndex].isFavouriteSlide = 0;
            setPhotos(photos)
            remFvtPhoto(photos[slideIndex].Key);
        } else {
            addToFvtPhotos(photos[slideIndex].Key);
            photos[slideIndex].isFavouriteSlide = 1;
            setPhotos(photos)
        }
        setIsFavourite((prevIsFavourite) => {
            const newIsFavourite = !prevIsFavourite;
            return newIsFavourite;
        })

    }

    const handleDownload = async (targetPhotoHdUrl, targetPhotoHd) => {
        const split_arr = targetPhotoHd.split('/')
        const linkDownload = document.createElement('a');
        linkDownload.href = targetPhotoHdUrl;
        linkDownload.download = split_arr[split_arr.length - 1]// Replace with desired filename
        linkDownload.target = "_blank";
        linkDownload.click();
    };

    console.log("FinalPhotos", photos)

    useEffect(() => {
        targetLink && getPhotosUrls(targetLink)
    }, [targetLink])

    return (
        <div className='mx-2 md:mx-5  mt-5 min-h-screen' >

            {

                photos.length > 0 &&
                (
                    <>
                        <Masonry
                            breakpointCols={
                                {
                                    // Number of columns by default
                                    default: 4,
                                    // Number of columns for screens 1100px and above
                                    1100: 3,
                                    // Number of columns for screens 700px and above
                                    700: 2
                                }}
                            className="my-masonry-grid gap-1 md:gap-3"
                            columnClassName="my-masonry-grid_column"
                        >
                            {photos.map((items, index) => {
                                return (
                                    <>
                                        <div key={index}>

                                            {/* <button onClick={() => openModal(items.Key)}> */}
                                            <button onClick={() => setSlideIndex(index)}>
                                                <img src={items.url} alt={`${items.Key}`} className='object-cover w-full h-full rounded-md mb-2 zoom-trans' />
                                            </button>

                                        </div>

                                        {isModalOpen && (imgKey === items.Key && <Model onClose={closeModal} targetPhoto={items.Key} s3_sdk={s3} bucket={bucketName} tempPhotoIndex={index} allTempPhotos={photos} />)}

                                    </>
                                )
                            })}
                        </Masonry>
                        <Lightbox
                            on={{
                                view: (index) => {
                                    console.log(index.index)
                                    setSlideIndex(index.index);
                                },
                            }}
                            index={slideIndex}
                            slides={photos}
                            open={slideIndex >= 0}
                            close={() => setSlideIndex(-1)}
                            render={{
                                iconLoading: () =>
                                    <img className='' src={loader} alt="Loading............" />,
                                iconPrev: () =>
                                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`} style={{ fontSize: "40px" }}>
                                        <MdNavigateBefore />
                                    </button>,
                                iconNext: () =>
                                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`} style={{ fontSize: "40px" }}>
                                        <MdNavigateNext />
                                    </button>,
                                iconClose: () =>
                                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}>
                                        <AiFillCloseCircle />
                                    </button>
                                ,
                            }}
                            toolbar={{
                                buttons: [
                                    slideIndex >= 0 ?
                                        <button
                                            onClick={() => {
                                                handleFavourite(photos[slideIndex].Key)
                                            }}
                                            className={`pr-2 text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}
                                        >
                                            {
                                                photos[slideIndex].isFavouriteSlide === 1 ? <AiFillStar /> : <AiOutlineStar />
                                            }
                                        </button> : "",
                                    <button
                                        onClick={() => handleDownload(photos[slideIndex].downloadUrl, photos[slideIndex].Key)}
                                        className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}
                                    >
                                        < AiOutlineDownload />
                                    </button>,
                                    "close",
                                ],
                            }}
                            plugins={[Captions]}

                        />
                    </>
                )
            }




        </div>
    )
}
