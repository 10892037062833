import React, { useState, useEffect } from 'react'
import Loading from './Loading';
import { UserAuth } from '../context/AuthContext';
import { AiOutlineDownload, AiFillCloseCircle, AiOutlineStar, AiFillStar } from 'react-icons/ai'
import '../index.css'
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";


const Model = ({ onClose, targetPhoto, s3_sdk, bucket, tempPhotoIndex, allTempPhotos }) => {


    var arrayPhotos = allTempPhotos;

    const [allPhotos, setAllPhotos] = useState(allTempPhotos)
    const [photoIndex, setPhotoIndex] = useState(tempPhotoIndex);



    const [photo, setPhoto] = useState(null)
    const [isFavourite, setIsFavourite] = useState(false);
    const { addToFvtPhotos, Link, remFvtPhoto, db_read } = UserAuth()
    const targetPhotoHd = Link.userType === 'Standard' ? targetPhoto : targetPhoto.replace("T_", "")
    const params = {
        Bucket: bucket,
        Key: targetPhotoHd
    }

    const getPhoto = async () => {

        try {
            // await db_read()
            // await Link && Link.FavoritePhotos.map((item) => {
            //     if (targetPhoto === item) {
            //         return setIsFavourite(!isFavourite)
            //     }
            // }
            // )
            // fetching data from wasabi
            console.log("calling......")
            const data = await s3_sdk.getObject(params).promise();

            // creating the blob from the binary data
            const blob = new Blob([data.Body], { type: 'image/jpeg' });
            // Create an object URL from the Blob
            const url = URL.createObjectURL(blob);

            console.log("click imageUrl", url);

            setPhoto(url)

            // setAllPhotos(
            //     allPhotos.map((item) => {
            //         return item.id === photoIndex ? { ...item, blobUrl: url } : item;
            //     })
            // );
            arrayPhotos[photoIndex].blobUrl = url

            // console.log('arrayPhotos', arrayPhotos)

            // let tempNext = photoIndex + 1

            if (photoIndex === 0) {
                // previous photo
                getNextUrl(arrayPhotos.length - 1);
            } else {
                getNextUrl(photoIndex - 1);
            }

            if (photoIndex === arrayPhotos.length - 1) {
                getNextUrl(0);
            } else {
                getNextUrl(photoIndex + 1);
            }

            return url;
        }
        catch (e) {
            console.log("error occured: ", e.message)
        }
    }

    const getNextUrl = async (nextIndex) => {

        try {
            console.log('getNextUrl', nextIndex)
            let targetPhotoHd = Link.userType === 'Standard' ? allPhotos[nextIndex].Key : allPhotos[nextIndex].Key.replace("T_", "")
            let params = {
                Bucket: bucket,
                Key: targetPhotoHd
            }

            // fetching data from wasabi
            console.log("calling......")
            const data = await s3_sdk.getObject(params).promise();

            // creating the blob from the binary data
            const blob = new Blob([data.Body], { type: 'image/jpeg' });
            // Create an object URL from the Blob
            const url = URL.createObjectURL(blob);

            console.log("click imageUrl", url);

            // setAllPhotos(
            //     allPhotos.map((item) => {
            //         return item.id === nextIndex ? { ...item, blobUrl: url } : item;
            //     })
            // );

            arrayPhotos[nextIndex].blobUrl = url;

            return url;
        }
        catch (e) {
            console.log("error occured: ", e.message)
        }

    }


    // const handleDownload = () => {
    //     const split_arr = targetPhotoHd.split('/')

    //     const link = document.createElement('a');
    //     link.href = photo;
    //     link.download = split_arr[split_arr.length - 1]// Replace with desired filename



    //     link.click();

    // };

    const handleDownload = () => {
        const split_arr = allPhotos[photoIndex].Key.split('/')

        const link = document.createElement('a');
        link.href = photo;
        link.download = split_arr[split_arr.length - 1]// Replace with desired filename

        // debugger
        link.click();

    };



    const buttonClass = isFavourite ? '' : 'bounce';

    const handleFavourite = () => {
        if (arrayPhotos[photoIndex].isFavouriteSlide === 1) {
            remFvtPhoto(arrayPhotos[photoIndex].Key);
            arrayPhotos[photoIndex].isFavouriteSlide = 0;
        } else {
            addToFvtPhotos(arrayPhotos[photoIndex].Key);
            arrayPhotos[photoIndex].isFavouriteSlide = 1;
        }
        setIsFavourite((prevIsFavourite) => {
            const newIsFavourite = !prevIsFavourite;
            return newIsFavourite;
        })
    }


    useEffect(() => {
        targetPhotoHd && getPhoto()
    }, [targetPhoto])

    const handleModalClick = (e) => {
        // Close the modal if the user clicks outside of it
        if (e.target === e.currentTarget) {
            onClose();
        }
    }

    const handlePrevious = () => {

        console.log(photoIndex);
        if (photoIndex === 0) {
            setPhotoIndex(arrayPhotos.length - 1);
            if (arrayPhotos[arrayPhotos.length - 1].blobUrl !== "") {
                setPhoto(arrayPhotos[arrayPhotos.length - 1].blobUrl)
            }
            if (arrayPhotos[arrayPhotos.length - 2].blobUrl === "") {
                getNextUrl(arrayPhotos.length - 2);
            }
        } else {
            setPhotoIndex(photoIndex - 1);
            if (arrayPhotos[photoIndex - 1].blobUrl !== "") {
                setPhoto(arrayPhotos[photoIndex + 1].blobUrl)
            } else {
            }
            if (photoIndex - 2 === -1) {
                if (arrayPhotos[arrayPhotos.length - 1].blobUrl === "") {
                    getNextUrl(arrayPhotos.length - 1);
                }
            } else {
                if (arrayPhotos[photoIndex - 2].blobUrl === "") {
                    // setPhotoIndex(photoIndex - 1);
                    getNextUrl(photoIndex - 2);
                }
            }


        }
    }

    const handleNext = async () => {

        // console.log('Test arrayIndex', arrayPhotos)
        // console.log('Test blobUrlIndex', photoIndex+1);
        // console.log('Test blobUrlIndex', photoIndex+1);
        // console.log('Test blobUrl', arrayPhotos[photoIndex+1].blobUrl)

        // console.log('this url check last ',arrayPhotos[photoIndex+1].blobUrl)
        // setPhoto(arrayPhotos[photoIndex+1].blobUrl)
        // // await getNextUrl(photoIndex+1);

        console.log(photoIndex);
        if (photoIndex === arrayPhotos.length - 1) {
            setPhotoIndex(0);
            if (arrayPhotos[0].blobUrl !== "") {
                setPhoto(arrayPhotos[0].blobUrl)
            } else {
            }
            if (arrayPhotos[1].blobUrl === "") {
                getNextUrl(1);
            }
        } else {
            setPhotoIndex(photoIndex + 1);
            // arrayPhotos[photoIndex + 1].blobUrl = (photoIndex + 1).toString()
            if (arrayPhotos[photoIndex + 1].blobUrl !== "") {
                setPhoto(arrayPhotos[photoIndex + 1].blobUrl)
            } else {
            }
            if (arrayPhotos[photoIndex + 2].blobUrl === "") {
                // setPhotoIndex(photoIndex + 1);
                getNextUrl(photoIndex + 2);
            }
        }
        // console.log("Test", arrayPhotos);
    }


    return (
        <div className="">
            {photo ?
                (
                    <>
                    <div className="fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-80" onClick={handleModalClick}>
                        <div className="inset-0 ">
                            <div className="rounded-lg  max-w-screen-md  w-full overflow-hidden border3-shadow">

                                <button className={`text-5xl/ text-[#FF512F] pri_btn icons-size ${buttonClass}`} style={{ top: "0",fontSize:"50px" }}
                                    onClick={() => {
                                        handlePrevious()
                                    }}
                                >
                                    <MdNavigateBefore />
                                </button>
                                <button className={`text-5xl/ text-[#FF512F] next_btn icons-size ${buttonClass}`} style={{top:0,fontSize:"50px"}}
                                    onClick={() => {
                                        handleNext()
                                    }}
                                >
                                    <MdNavigateNext />
                                </button>
                                <div className='flex items-center justify-between fixed min-w-full left-0 top-5 z-30'> {/*top-5 right-10*/}
                                    <p className='pr-2 text-5xl img_title text-[#FF512F]' style={{fontSize:"18px", padding:"10px 20px"}}> {arrayPhotos[photoIndex].imageName} </p>
                                    <div className='flex flex-row ' style={{padding:"10px 20px"}}>

                                        <button
                                            onClick={() => {
                                                handleFavourite()
                                            }}
                                            className={`pr-2 text-5xl/ text-[#FF512F] icons-size  ${buttonClass}`} 
                                        >
                                            {/* {isFavourite ? <AiFillStar /> : <AiOutlineStar />} */}
                                            {arrayPhotos[photoIndex].isFavouriteSlide === 1 ? <AiFillStar /> : <AiOutlineStar />}
                                        </button>
                                        <button
                                            onClick={handleDownload}
                                            className={`text-5xl/ text-[#FF512F] icons-size  ${buttonClass}`}
                                        >
                                            < AiOutlineDownload />
                                        </button>

                                        <button
                                            onClick={onClose}
                                            className="ml-10 text-xl text-[#FF512F] icons-size"
                                        >
                                            <AiFillCloseCircle />
                                        </button>
                                    </div>
                                </div>

                                <div className='h-full overflow-visible'>
                                    <img src={photo} alt="Original" className='rounded-lg w-full h-full phhh' />
                                </div>

                            </div>
                        </div>
                    </div>
                    </>
                ) : <div className='fixed inset-0 flex justify-center items-center z-50 bg-black bg-opacity-50'><Loading /></div>}
        </div>
    );
};

export default Model