import React from 'react'
import useLongPress from '../utils/useLongPress';
const LongPressButton = ({ handleLongPress, handleTempClick, item, index }) => {
    const defaultOptions = {
        shouldPreventDefault: true,
        delay: 500,
    };

    const handleLongPressCallback = (event) => {
        event.preventDefault(); // Prevent any default behavior
        handleLongPress(item, index);
    };

    const handleClickCallback = () => {
        handleTempClick(index);
    };

    const buttonEvents = useLongPress(
        handleLongPressCallback,
        handleClickCallback,
        defaultOptions
    );

    return (
        <button {...buttonEvents}>
            <img src={item.url} alt={`${item.imageName}`} className='object-cover w-full h-full rounded-md img-preview' />
        </button>
    );
};

export default LongPressButton