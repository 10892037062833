import { useCallback, useRef, useState } from "react";

const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();
  const touchMoved = useRef(false);

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchmove", handleTouchMove);
        target.current = event.target;
      }

      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      clearTimeout(timeout.current);

      if (!longPressTriggered && shouldTriggerClick && !touchMoved.current) {
        onClick(event);
      }

      setLongPressTriggered(false);
      touchMoved.current = false;

      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchmove", handleTouchMove);
      }
    },
    [onClick, longPressTriggered, shouldPreventDefault]
  );

  const handleTouchStart = (event) => {
    start(event);
  };

  const handleTouchEnd = (event) => {
    clear(event);
  };

  const handleTouchMove = () => {
    touchMoved.current = true;
    clearTimeout(timeout.current);
  };

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => handleTouchStart(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => handleTouchEnd(e),
  };
};

export default useLongPress;
