import React, { useState, useEffect } from 'react'
import { useLocation, useMatch } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';
import Modal from '../components/Modal';
import Loading from '../components/Loading';
import loader from '../Assets/images/movie-reel.svg'
import Masonry from "react-masonry-css"
import ReactPaginate from 'react-paginate';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { AiFillCloseCircle, AiFillStar, AiOutlineDownload, AiOutlineStar, AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import { FaCopy } from "react-icons/fa";
import Model from '../components/model';
import { Checkbox, Radio } from 'flowbite-react';
import { DocumentReference, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, Slide, toast } from 'react-toastify';

const pageSize = 100;


export const AlbumPhotos = () => {
    const [data, setData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [imgKey, setImgKey] = useState(null)
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFavourite, setIsFavourite] = useState(false);
    const [allPhotosArr, setAllPhotosArr] = useState([])

    var sharedPhotos = []

    const [photos, setPhotos] = useState(null)
    const [videos, setVideos] = useState(null)


    const buttonClass = isFavourite ? 'bounce' : '';

    const location = useLocation();
    const targetMedia = location.state;

    const { user, addToFvtPhotos, Link, remFvtPhoto, db_read, UuidGenerater, db_shared_read } = UserAuth()
    const targetLink = Link && Link[targetMedia.target]

    console.log("targetLink", Link);

    const openModal = (key) => {
        setIsModalOpen(true);
        setImgKey(key)
    };

    const closeModal = () => {
        db_read()
        setIsModalOpen(false);

    };



    // wasabi credentials
    const wasabi_key_id = process.env.REACT_APP_WASABI_ACCESS_KEY_ID
    const wasabi_key = process.env.REACT_APP_WASABI_ACCESS_KEY
    const bucketName = "digialbum";
    const region = "ap-southeast-1"; // Update with your bucket's region
    const endpoint = "https://s3.ap-southeast-1.wasabisys.com"; // Update with your Wasabi endpoint
    // const folderName = "Personal/Birthday/Photos/"; 

    // wasabi credentials
    const s3 = new AWS.S3({
        region: region,
        endpoint: endpoint,
        credentials: {
            accessKeyId: wasabi_key_id,
            secretAccessKey: wasabi_key
        }
    });
    AWS.config.update({
        region: region,
        accessKeyId: wasabi_key_id,
        secretAccessKey: wasabi_key,
    });



    const getPhotosUrls = async (link) => {

        console.log("Links to", link)
        const promise = link.map((path, index) => {

            console.log("path", path.key)
            console.log("indexdata", index)
            const params = {
                Bucket: bucketName,
                Key: path.key,
            }

            const targetPhotoHd = path.key
            const slideParams = { Bucket: bucketName, Key: targetPhotoHd };

            // setPhotosPath((prev) => [...prev, path])

            const signedUrl = s3.getSignedUrl('getObject', params);
            const signedUrlFull = s3.getSignedUrl('getObject', slideParams);

            const parts = path.key.split('/')

            // Find the last dot (.) in the string
            const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
            let resultString
            if (lastDotIndex !== -1) {
                // Extract the part of the string before the last dot
                resultString = parts[parts.length - 1].substring(0, lastDotIndex);
            } else {
                // If there is no dot in the string, keep the original string
                resultString = parts[parts.length - 1];
            }

            let obj = {
                id: index,
                url: signedUrl,
                downloadUrl: signedUrlFull,
                Key: path.key,
                isFavouriteSlide: 1,
                blobUrl: "",
                imageName: resultString,
                src: signedUrlFull,
                title: resultString,
                comment: path.value,
            }

            Tempphotos.push(obj)

            setPhotos(Tempphotos);
            setData(Tempphotos)

            return signedUrl

        })

        // Wait for all signed URL promises to resolve
        // Promise.all(promise)
        //     .then((urls) => {
        //         setPhotosUrls(urls);
        //     })
        //     .catch((error) => {
        //         console.error('Error generating signed URLs:', error.message);
        //     });
    }

    const getData = async (pageNumber, continuationToken = undefined) => {

        const params = {
            Bucket: bucketName,
            MaxKeys: pageSize,
            Prefix: targetLink,
            ContinuationToken: continuationToken,
        };

        if (pageNumber === 1) {
            const data_total = await s3.listObjectsV2({
                Bucket: bucketName,
                Prefix: targetLink,
            }).promise();
            const totalPages = Math.ceil(data_total.KeyCount / pageSize);
            setTotalCount(totalPages);
        }

        if (pageNumber > 1) {
            const objectsToSkip = (pageNumber - 1) * pageSize;
            const initialPage = await s3.listObjectsV2(params).promise();
            params.ContinuationToken = initialPage.NextContinuationToken;

            for (let i = 0; i < ((objectsToSkip / pageSize) - 1); i++) {
                const nextPage = await s3.listObjectsV2(params).promise();
                params.ContinuationToken = nextPage.NextContinuationToken;
            }
        }


        try {
            const response = await s3.listObjectsV2(params).promise();
            const conv_data = await JSON.parse(JSON.stringify(response.Contents))
            setData(conv_data)
            getPhoto(conv_data)
            return response;
        } catch (error) {
            throw error;
        }

    }

    const favoritePhotos = Link && Link.FavoritePhotos

    useEffect(() => {

        // if (location) {

        //     getDoc(doc(db, 'gallery', linkId)).then((response) => {

        //         console.log("response", response)
        //         if (response.data()?.images) {
        //             getPhotosUrls(response.data()?.images)
        //         } else {
        //             setData([])
        //         }

        //     }).catch((error) => {
        //         console.log("shared target error", error);
        //     });

        console.log("shared target", targetLink);
        targetLink && getPhotosUrls(targetLink)
        // } else {

        // }


    }, [favoritePhotos, targetLink, currentPage]);


    const handlePageChange = (e) => {
        setCurrentPage(e.selected + 1);
        window.scrollTo(0, 0)
    };

    // const targetPhotoHd = Link.userType === 'Standard' ? targetPhoto : targetPhoto.replace("T_", "")

    // const params = {
    //   Bucket: bucket,
    //   Key: targetPhotoHd
    // }

    var Tempphotos = []
    var Tempvideos = []
    var slides = []


    // const getPhoto = async (params) => {

    //   try {
    //     await db_read()
    //     // await Link && Link.FavoritePhotos.map((item) => {
    //     //   if (targetPhoto === item) {
    //     //     return setIsFavourite(!isFavourite)
    //     //   }
    //     // }
    //     // )
    //     // fetching data from wasabi
    //     console.log("calling......")
    //     const data = await s3.getObject(params).promise();

    //     // creating the blob from the binary data
    //     const blob = new Blob([data.Body], { type: 'image/jpeg' });
    //     // Create an object URL from the Blob
    //     const url = URL.createObjectURL(blob);

    //     console.log("click imageUrl",url);

    //     // setPhoto(url)
    //     console.log(data, url)
    //     return url;
    //   }
    //   catch (e) {
    //     console.log("error occured: ", e.message)
    //   }
    // }


    const getPhoto = (photosData) => {

        photosData.slice(1).map((i, index) => {

            const params = { Bucket: bucketName, Key: i.Key };
            const targetPhotoHd = i.Key
            const slideParams = { Bucket: bucketName, Key: targetPhotoHd };

            const isImage = /\.(JPG|PNG|png|jpeg|jpg)$/.test(i.Key);
            const isVideo = /\.(mp4|webm|mkv|mov)$/.test(i.Key);
            const signedUrl = s3.getSignedUrl('getObject', params);
            const signedUrlFull = s3.getSignedUrl('getObject', slideParams);

            const parts = i.Key.split('/')

            // Find the last dot (.) in the string
            const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
            let resultString
            if (lastDotIndex !== -1) {
                // Extract the part of the string before the last dot
                resultString = parts[parts.length - 1].substring(0, lastDotIndex);
            } else {
                // If there is no dot in the string, keep the original string
                resultString = parts[parts.length - 1];
            }

            let obj = {
                id: index,
                url: signedUrl,
                downloadUrl: signedUrlFull,
                Key: i.Key,
                isFavouriteSlide: favoritePhotos.find((elem) => i.Key === elem) ? 1 : 0,
                blobUrl: "",
                imageName: resultString,
                src: signedUrlFull,
                title: resultString,
            }
            if (isImage) {
                Tempphotos.push(obj)

                setPhotos(Tempphotos)

                let slideObj = {
                    src: signedUrlFull,
                    downloadUrl: signedUrlFull,
                    title: resultString,
                }

                slides.push(slideObj);

            }

            if (isVideo) {

                console.log("innerVideo", obj)
                Tempvideos.push(obj)
                setVideos(Tempvideos)
            }
        })
    }


    const [slideIndex, setSlideIndex] = React.useState(-1);

    const handleFavourite = (targetPhoto) => {

        console.log(slideIndex, photos[slideIndex].isFavouriteSlide, photos[slideIndex].Key)

        if (photos[slideIndex].isFavouriteSlide === 1) {
            photos[slideIndex].isFavouriteSlide = 0;
            setPhotos(photos)
            remFvtPhoto(photos[slideIndex].Key);
        } else {
            addToFvtPhotos(photos[slideIndex].Key);
            photos[slideIndex].isFavouriteSlide = 1;
            setPhotos(photos)
        }
        setIsFavourite((prevIsFavourite) => {
            const newIsFavourite = !prevIsFavourite;
            return newIsFavourite;
        })

    }

    const handleDownload = async (targetPhotoHdUrl, targetPhotoHd) => {
        const split_arr = targetPhotoHd.split('/')
        const linkDownload = document.createElement('a');
        linkDownload.href = targetPhotoHdUrl;
        linkDownload.download = split_arr[split_arr.length - 1]// Replace with desired filename
        linkDownload.target = "_blank";
        linkDownload.click();
    };

    const handleSelectShare = (e, key) => {
        if (e.target.checked) {
            sharedPhotos.push(key);
        } else {
            sharedPhotos = sharedPhotos.filter((elem) => elem !== key)
        }
    }


    const handleChangeForm = (e, index) => {
        const { name, value } = e.target;

        console.log('handleChangeForm', name);

        photos[slideIndex].comment = value;
        setPhotos(photos)
        // photos.

        // setPhotos((prev) => {
        //     return {
        //         ...prev,
        //         [name]: value,
        //     };
        // });

    }

    const handleSubmit = async (e) => {

        console.log("photos", photos);
        let valueAlbum = photos.map((elem) => {
            return { key: elem.Key, value: elem.comment }
        })

        // console.log("valueAlbum", valueAlbum);

        const ref = doc(db, "gallery", user.uid);

        await updateDoc(ref, {
            AlbumPhotos: valueAlbum,
        }).then(() => {
            toast.success("Thank you for comment.", {
                autoClose: 1000,
                transition: Slide,
            });
        }).catch((error) => {
            toast.error(error.message, {
                autoClose: 1000,
                transition: Slide,
            });
        });
    }


    return (
        <>
            <div className='mx-2 md:mx-5  mt-5 min-h-screen scroll-auto'>
                {data ? (

                    photos && photos.length > 0 ? (
                        <>
                            <Masonry
                                breakpointCols={
                                    {
                                        // Number of columns by default
                                        default: 4,
                                        // Number of columns for screens 1100px and above
                                        1100: 3,
                                        // Number of columns for screens 700px and above
                                        700: 2
                                    }}
                                className="my-masonry-grid gap-1 md:gap-3"
                                columnClassName="my-masonry-grid_column"
                            >

                                {photos.map((items, index) => {
                                    return (
                                        <>
                                            <div key={items.Key} className={``}>
                                                {/* <button onClick={() => openModal(items.Key)}> */}
                                                <button onClick={() => setSlideIndex(index)}>
                                                    <img src={items.url} alt={`${items.Key}`} className='object-cover w-full h-full rounded-md img-preview' />
                                                </button>
                                            </div>

                                            {isModalOpen && (imgKey === items.Key && <Model onClose={closeModal} targetPhoto={items.Key} s3_sdk={s3} bucket={bucketName} tempPhotoIndex={index} allTempPhotos={photos} />)}
                                        </>
                                    )

                                })}
                            </Masonry>

                        </>
                    ) :

                        videos && videos.length > 0 && (
                            <Masonry
                                breakpointCols={
                                    {
                                        // Number of columns by default
                                        default: 3,
                                        // Number of columns for screens 1100px and above
                                        1100: 2,
                                        // Number of columns for screens 700px and above
                                        700: 1
                                    }}
                                className="my-masonry-grid gap-1"
                                columnClassName="my-masonry-grid_column"
                            >
                                {
                                    videos.map((i, index) => {

                                        const parts = i.Key.split('/')

                                        // Find the last dot (.) in the string
                                        const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
                                        let resultString
                                        if (lastDotIndex !== -1) {
                                            // Extract the part of the string before the last dot
                                            resultString = parts[parts.length - 1].substring(0, lastDotIndex);
                                        } else {
                                            // If there is no dot in the string, keep the original string
                                            resultString = parts[parts.length - 1];
                                        }

                                        return (
                                            <div key={i.Key} className={`flex flex - wrap rounded - md mb - 2 md: mb - 5 mx - 1 md: mx - 2`}>

                                                <video src={i.url} className='w-full h-auto' controls />
                                                <h1 className='text-black text-sm font-medium '>{resultString}</h1>
                                            </div>
                                        )

                                    })
                                }
                            </Masonry>
                        )



                ) : <Loading />

                }


                <Lightbox
                    on={{
                        view: (index) => {
                            setSlideIndex(index.index);
                        },
                    }}
                    index={slideIndex}
                    slides={photos}
                    open={slideIndex >= 0}
                    close={() => setSlideIndex(-1)}
                    render={{
                        iconLoading: () =>
                            <img className='' src={loader} alt="Loading............" />,
                        iconPrev: () =>
                            <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`} style={{ fontSize: "40px" }}>
                                <MdNavigateBefore />
                            </button>,
                        iconNext: () =>
                            <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`} style={{ fontSize: "40px" }}>
                                <MdNavigateNext />
                            </button>,
                        iconClose: () =>
                            <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}>
                                <AiFillCloseCircle />
                            </button>
                        ,
                        slideFooter: ({ slide, rect }) =>
                            (slide) ? (
                                <>
                                    <div class="yarl__slide_captions_container yarl__slide_description_container">
                                        <div class="yarl__slide_description">
                                            <div className="mb-6 md:mb-6 text-center">
                                                <input type="text" id="comment" name={`${slideIndex}-comment`} className="inbut-border rounded-md md:round-lg h-[30px] md:h-auto sm:w-[600px] md:w-[700px] lg:w-[800px]  text-[#000000]" placeholder="Enter comment here"
                                                    defaultValue={slide.comment}
                                                    onChange={(e) => handleChangeForm(e, slideIndex)}
                                                />
                                            </div>
                                            <button type="submit" className="btn-grad w-100 text-center comment-btn" onClick={(e) => handleSubmit(e)}>Submit</button>
                                            <br />
                                        </div>
                                    </div>
                                </>
                            ) : undefined,

                    }}
                    toolbar={{
                        buttons: [
                            <button
                                onClick={() => handleDownload(photos[slideIndex].downloadUrl, photos[slideIndex].Key)}
                                className={`text - 5xl / text - [#FF512F] icons - size ${buttonClass}`}
                            >
                                < AiOutlineDownload />
                            </button>,
                            "close",
                        ],
                    }}
                    plugins={[Zoom, Captions]}
                />

            </div>

            {/* <Pagination totalCount={totalCount} onPageChange={handlePageChange} currentPage={currentPage} /> */}
            {/* <div className='flex justify-center'>
        <div className="fixed bottom-0 p-2 md:p-4 bg-black rounded-md bg-opacity-50 z-10 mb-2 md:mb-5 " style={{ zIndex: 1 }}>
          <div className='flex justify-center space-x-3'>
            <ReactPaginate
              previousLabel={"<<"}
              nextLabel={">>"}
              breakLabel={"•••"}
              pageCount={totalCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"flex flex-row justify-center space-x-3"}
              pageClassName={"btn-grad"}
              pageLinkClassName={""}
              previousClassName={"font-bold text-lg text-white"}
              previousLinkClassName={"font-bold text-lg"}
              nextClassName={"font-bold text-lg text-white"}
              nextLinkClassName={"font-bold text-lg"}
              breakClassName={"space-x-2"}
              breakLinkClassName={"text-white"}
              activeClassName={"border border-2 border-white"}
            />
          </div>
        </div>
      </div> */}

            <ToastContainer />
        </>
    )
}
