import React from 'react'
const posts = [
    {
      id: 1,
      title: 'Boost your conversion rate',
      href: '#',
      description:
        'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
      date: 'Mar 16, 2020',
      datetime: '2020-03-16',
      category: { title: 'Marketing', href: '#' },
      author: {
        name: 'Michael Foster',
        role: 'Co-Founder / CTO',
        href: '#',
        imageUrl:
          'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      },
    },
    // More posts...
  ]

export const Cards = () => {
    return (

        <div className='flex flex-col'>
            
            {/* card */} 
            <div className='m-6 md:m-14 flex flex-col mt-0 md:mt-0'> 
                
 
                <div className='title_section text-2xl text-center my-5 text-balck md:text-4xl font-extrabold font-mono'>
                    What does digiAlbum offer ?
                </div>

                <div className='box2'>
                    <div className='Box_section'>
                        <div className={`col-span-full md:grid-start-1 md:col-span-1 box `}>
                            <div class="max-w-sm bg-white  rounded-lg dark:bg-gray-800 dark:border-gray-700 box-shadow box-padding neuromorphic ">
                                {/* Image */}
                                <div className='max-h-sm'>
                                    <img class="image-icon" src={require('../Assets/images/11.gif')} alt=""/>
                                </div>
                                {/* Title and Description */}
                                <div class="px-2">
                                    <a href="/#"  className='text-center'>
                                        <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Invitation website.</h5>
                                    </a>
                                    <p className="my-5 text-sm leading-6 text-gray-600">
                                        <ul>
                                            <li>&#x1f44d;  Attractive timer.</li>
                                            <li>&#x1f44d; Pre-wedding/engagement photo gallery.</li>
                                            <li>&#x1f44d; Youtube video/Live stream of your wedding.</li>
                                            <li>&#x1f44d; RSVP/ Attendance message, and many more...</li>
                                        </ul>
                                    </p> 
                                </div>
                            </div>
                        </div>
                        <div className={`col-span-full md:grid-start-1 md-mt-5 md:col-span-1 box`}>
                            <div class="max-w-sm bg-white  rounded-lg  dark:bg-gray-800 dark:border-gray-700 box-shadow box-padding neuromorphic">
                                {/* Image */}
                                <div className='max-h-sm'>
                                    <img class="image-icon" src={require('../Assets/images/22.jpeg')} alt=""/>
                                </div>
                                {/* Title and Description */}
                                <div class="px-2">
                                    <a href="/#"  className='text-center'>
                                        <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Customized App.</h5>
                                    </a>
                                    <p className="my-5 text-sm leading-6 text-gray-600">
                                        <ul>
                                            <li>&#x1f44d; App with your photo as App icon and your name As App name.</li>
                                            <li>&#x1f44d; Share your photos with your guests and friends</li>
                                            <li>&#x1f44d; Access it anywhere anytime without using up the device space. </li>


                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`col-span-full md:grid-start-1 md:col-span-1 box`}>
                            <div class="max-w-sm bg-white  rounded-lg  dark:bg-gray-800 dark:border-gray-700  box-shadow box-padding neuromorphic">
                                {/* Image */}
                                <div className='max-h-sm'>
                                    <img class="image-icon" src={require('../Assets/images/33.gif')} alt=""/>
                                </div>
                                {/* Title and Description */}
                                <div class="px-2 ">
                                    <a href="/#" className='text-center'>
                                        <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Safe Storage.</h5>
                                    </a>
                                    <p className="my-5 text-sm leading-6 text-gray-600">
                                        <ul>
                                            <li>&#x1f44d; Download your media in high quality anytime, anywhere.</li>
                                            <li>&#x1f44d; No need to purchase hardisk, as your media is safe on the cloud.</li>
                                            <li>&#x1f44d; No worry of data getting lost. </li>
                                            <li>&#x1f44d; Turn your digital dreams into reality with a one-of-a-kind experience! </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`col-span-full md:grid-start-1 md:col-span-1 box`}>
                            <div class="max-w-sm bg-white  rounded-lg  dark:bg-gray-800 dark:border-gray-700  box-shadow box-padding neuromorphic">
                                {/* Image */}
                                <div className='max-h-sm'>
                                    <img class="image-icon" src={require('../Assets/images/display.gif')} alt=""/>
                                </div>
                                {/* Title and Description */}
                                <div class="px-2 ">
                                    <a href="/#" className='text-center'>
                                        <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">TV App.</h5>
                                    </a>
                                    <p className="my-5 text-sm leading-6 text-gray-600">
                                        <ul>
                                            <li>&#x1f44d;  TV app to enjoy your moments on a large screen</li>
                                            <li>&#x1f44d; Enjoy the photos and videos on your android TV without the hassel of connecting hardisk.</li>
                                            <li> <a href="https://www.digialbum.in/contact"><b>Contact Us for details</b></a></li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='title_section text-2xl text-center my-5 text-balck md:text-4xl font-extrabold font-mono'>
                Can you imagine losing ALL of your precious <br/> photos and videos, forever?
            </div> 
                <div className='box-1'>
                    <div className='Box_section'>
                        <div className={`col-span-full md:grid-start-1 md:col-span-1 box`}>
                            <div class="max-w-sm bg-white  rounded-lg dark:bg-gray-800 dark:border-gray-700 box-shadow box-padding neuromorphic">
                                {/* Image */}
                                <div className='max-h-sm'>
                                        <img class="rounded-t-lg" src={require('../Assets/images/homecard1.jpg')} alt="" />
                                    </div>
                                    {/* Title and Description */}
                                    <div class="p-2 md:p-5">
                                        <a href="/#" className='text-center'>
                                            <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">🔹 Hard Disk/Laptop 🚫</h5>
                                        </a>
                                        <p className="mt-5 text-sm leading-6 text-gray-600">
                                            <ol className='text-left'>
                                                <li> &#x1f44d; Limited recovery options, risking permanent data loss</li>
                                                <li>&#x1f44d; Vulnerable to accidents and mishandling</li>
                                                <li>&#x1f44d; Requires a laptop to access your memories</li>
                                            </ol>
                                        </p>
                                    </div>
                            </div>
                        </div>
                        <div className={`col-span-full md:grid-start-1 md-mt-5 md:col-span-1 box`}>
                            <div class="max-w-sm bg-white  rounded-lg  dark:bg-gray-800 dark:border-gray-700 box-shadow box-padding neuromorphic">
                                {/* Image */}
                                <div className='max-h-sm'>
                                    <img class="rounded-t-lg" src={require('../Assets/images/homecard2.jpg')} alt="" />
                                </div>
                                {/* Title and Description */}
                                <div class="p-2 md:p-5">
                                    <a href="/#" className='text-center'>
                                        <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">🔸Pendrive/Flashdrive 🧐</h5>
                                    </a>
                                    <p className="mt-5 text-sm leading-6 text-gray-600">
                                        <ol className='text-left'>
                                            <li> &#x1f44d; Susceptible to damage from heat, moisture, or magnetic fields</li>
                                            <li>&#x1f44d; No backup, meaning no recovery if data is lost</li>
                                            <li>&#x1f44d; Prone to virus infections and potential data loss or theft</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`col-span-full md:grid-start-1 md:col-span-1 box`}>
                            <div class="max-w-sm bg-white  rounded-lg  dark:bg-gray-800 dark:border-gray-700  box-shadow box-padding neuromorphic">
                                {/* Image */}
                                <div className='max-h-sm'>
                                    <img class="rounded-t-lg" src={require('../Assets/images/homecard3.png')} alt="" />
                                </div>
                                {/* Title and Description */}
                                <div class="p-2 md:p-5">
                                    <a href="/#" className='text-center'>
                                        <h5 class="mb-2 text-lg md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white">🌟Now, let's introduce you to digiAlbum 🌟</h5>
                                    </a>
                                    <p className="mt-5 text-sm leading-6 text-gray-600">
                                        <ol className='text-left'>
                                            <li> &#x1f44d; Your data is securely stored in the cloud</li>
                                            <li>&#x1f44d; Backed up on over 4+ servers, virtually eliminating data loss</li>
                                            <li>&#x1f44d; Affordable and designed exclusively for special events !</li>
                                        </ol>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>




    )
}
