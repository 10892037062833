import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import AWS, { Translate } from 'aws-sdk';
import Modal from '../components/Modal';
import Loading from '../components/Loading';
import loader from '../Assets/images/movie-reel.svg'
import Masonry from "react-masonry-css"
import ReactPaginate from 'react-paginate';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import { AiFillCloseCircle, AiFillStar, AiOutlineDownload, AiOutlineStar, AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { IoLogoWhatsapp, IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";
import { } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import { FaCopy, FaCheckDouble, FaCheckCircle, FaDownload } from "react-icons/fa";
import { ImRadioUnchecked } from "react-icons/im";
import Model from '../components/model';
import { Checkbox, Radio } from 'flowbite-react';
import { DocumentReference, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, Slide, toast } from 'react-toastify';
import useLongPress from '../utils/useLongPress';
import LongPressButton from '../components/LongPressButton';
import JSZip from 'jszip';

const pageSize = 100;


export const MediaView = () => {
  const [data, setData] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgKey, setImgKey] = useState(null)
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFavourite, setIsFavourite] = useState(false);
  const [allPhotosArr, setAllPhotosArr] = useState([])
  const [isSelected, setIsSelected] = useState("");
  const [showAllCheckbox, setShowAllCheckbox] = useState("");

  var sharedPhotos = []

  const [photos, setPhotos] = useState(null);
  const [videos, setVideos] = useState([]);


  const buttonClass = isFavourite ? 'bounce' : '';

  const location = useLocation();
  const targetMedia = location.state;

  const { user, addToFvtPhotos, Link, remFvtPhoto, db_read, UuidGenerater } = UserAuth()
  const targetLink = Link && Link[targetMedia.target]

  const openModal = (key) => {
    setIsModalOpen(true);
    setImgKey(key)
  };

  const closeModal = () => {
    db_read()
    setIsModalOpen(false);

  };



  // wasabi credentials
  const wasabi_key_id = process.env.REACT_APP_WASABI_ACCESS_KEY_ID
  const wasabi_key = process.env.REACT_APP_WASABI_ACCESS_KEY
  const bucketName = "digialbum";
  const region = "ap-southeast-1"; // Update with your bucket's region
  const endpoint = "https://s3.ap-southeast-1.wasabisys.com"; // Update with your Wasabi endpoint
  // const folderName = "Personal/Birthday/Photos/"; 

  // wasabi credentials
  const s3 = new AWS.S3({
    region: region,
    endpoint: endpoint,
    credentials: {
      accessKeyId: wasabi_key_id,
      secretAccessKey: wasabi_key
    }
  });
  AWS.config.update({
    region: region,
    accessKeyId: wasabi_key_id,
    secretAccessKey: wasabi_key,
  });

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const getData = async (pageNumber, continuationToken = undefined) => {

    const params = {
      Bucket: bucketName,
      MaxKeys: pageSize,
      Prefix: targetLink,
      ContinuationToken: continuationToken,
    };

    if (pageNumber === 1) {
      const data_total = await s3.listObjectsV2({
        Bucket: bucketName,
        Prefix: targetLink,
      }).promise();
      const totalPages = Math.ceil(data_total.KeyCount / pageSize);
      setTotalCount(totalPages);
    }

    if (pageNumber > 1) {
      const objectsToSkip = (pageNumber - 1) * pageSize;
      const initialPage = await s3.listObjectsV2(params).promise();
      params.ContinuationToken = initialPage.NextContinuationToken;

      for (let i = 0; i < ((objectsToSkip / pageSize) - 1); i++) {
        const nextPage = await s3.listObjectsV2(params).promise();
        params.ContinuationToken = nextPage.NextContinuationToken;
      }
    }


    try {
      const response = await s3.listObjectsV2(params).promise();
      const conv_data = await JSON.parse(JSON.stringify(response.Contents))
      setData(conv_data)
      getPhoto(conv_data)
      return response;
    } catch (error) {
      throw error;
    }

  }

  const favoritePhotos = Link && Link.FavoritePhotos

  useEffect(() => {
    targetLink && getData(currentPage)
  }, [favoritePhotos, targetLink, currentPage]);


  const handlePageChange = (e) => {
    setCurrentPage(e.selected + 1);
    window.scrollTo(0, 0)
  };

  var Tempphotos = []
  var Tempvideos = []
  var slides = []

  const getPhoto = (photosData) => {

    photosData.slice(1).map((i, index) => {

      const params = { Bucket: bucketName, Key: i.Key };


      const targetPhotoHd = Link.userType === 'Standard' ? i.Key : i.Key.replace("T_", "")

      const slideParams = { Bucket: bucketName, Key: targetPhotoHd };

      const isImage = /\.(JPG|PNG|png|jpeg|jpg)$/.test(i.Key);
      const isVideo = /\.(mp4|webm|mkv|mov)$/.test(i.Key);
      const signedUrl = s3.getSignedUrl('getObject', params);
      const signedUrlFull = s3.getSignedUrl('getObject', slideParams);

      const parts = i.Key.split('/')

      // Find the last dot (.) in the string
      const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
      let resultString
      if (lastDotIndex !== -1) {
        // Extract the part of the string before the last dot
        resultString = parts[parts.length - 1].substring(0, lastDotIndex);
      } else {
        // If there is no dot in the string, keep the original string
        resultString = parts[parts.length - 1];
      }

      let obj = {
        id: index,
        url: signedUrl,
        downloadUrl: signedUrlFull,
        Key: i.Key,
        isFavouriteSlide: favoritePhotos.find((elem) => i.Key === elem) ? 1 : 0,
        isSelected: sharedPhotos.find((elem) => i.Key === elem) ? true : false,
        blobUrl: "",
        imageName: resultString,
        src: signedUrlFull,
        title: resultString,
      }
      if (isImage) {
        Tempphotos.push(obj)
        setPhotos(Tempphotos)
        let slideObj = {
          src: signedUrlFull,
          downloadUrl: signedUrlFull,
          title: resultString,
        }

        slides.push(slideObj);

      }

      if (isVideo) {
        console.log("innerVideo", obj)
        Tempvideos.push(obj)

        // const objTemp = obj;
        // addObject(obj);

        // setVideos(Tempvideos)
      }
    })

    // console.log("TempVideos", Tempvideos);

    const sortedArray = Tempvideos.sort((a, b) => {
      const numA = extractNumericValue(a.Key);
      const numB = extractNumericValue(b.Key);
      return numA - numB; // Sort in ascending order
    });



    console.log("TempVideos", sortedArray);


    // setVideos(sortedArray);


    let TempFinalVideos = [];

    for (let i = 0; i < sortedArray.length; i++) {
      const keyNameExists = TempFinalVideos.some(obj => obj.Key.split("_")[0] === sortedArray[i].Key.split("_")[0]);
      if (!keyNameExists) {
        // If key name doesn't exist, add the new object to the array
        TempFinalVideos.push(sortedArray[i]);
        // setVideos(prevVideos => [...prevVideos, newObject]);
      } else {
        // If key name already exists, skip adding the object
        console.log(`Object with key name '${sortedArray[i].Key}' already exists.`);
      }
    }

    setVideos(TempFinalVideos);
  }

  const extractNumericValue = (str) => {
    const numericPart = str.split('_')[1]; // Extract numeric part after '_'
    return parseInt(numericPart);
  };

  const addObject = (newObject) => {

    // if (arrayObject.length === 1) {
    //   setVideos(videos => [...videos, newObject]);
    // } else {
    const arrayObject = newObject.Key.split("_");

    // const keyNameExists = videos.some(obj => obj.Key.split("_")[0] === arrayObject[0]);
    const keyNameExists = videos.some(obj => obj.Key === newObject.Key);
    if (!keyNameExists) {
      // If key name doesn't exist, add the new object to the array
      setVideos(prevVideos => [...prevVideos, newObject]);
    } else {
      // If key name already exists, skip adding the object
      console.log(`Object with key name '${newObject.Key}' already exists.`);
    }
    // }

  };

  const [slideIndex, setSlideIndex] = React.useState(-1);

  const handleFavourite = (targetPhoto) => {

    console.log(slideIndex, photos[slideIndex].isFavouriteSlide, photos[slideIndex].Key)

    if (photos[slideIndex].isFavouriteSlide === 1) {
      photos[slideIndex].isFavouriteSlide = 0;
      setPhotos(photos)
      remFvtPhoto(photos[slideIndex].Key);
    } else {
      addToFvtPhotos(photos[slideIndex].Key);
      photos[slideIndex].isFavouriteSlide = 1;
      setPhotos(photos)
    }
    setIsFavourite((prevIsFavourite) => {
      const newIsFavourite = !prevIsFavourite;
      return newIsFavourite;
    })

  }

  const handleDownload = async (targetPhotoHdUrl, targetPhotoHd) => {
    const split_arr = targetPhotoHd.split('/')
    const linkDownload = document.createElement('a');
    linkDownload.href = targetPhotoHdUrl;
    linkDownload.download = split_arr[split_arr.length - 1]// Replace with desired filename
    linkDownload.target = "_blank";
    linkDownload.click();
  };

  const handleLongSelectShare = (item, index) => {
    const updateCheckbox = photos.map((Checkbox) => Checkbox.id === index ? { ...Checkbox, isSelected: !item.selected } : Checkbox);
    setPhotos(updateCheckbox);
    if (!item.selected) {
      sharedPhotos.push(item.Key);
    } else {
      sharedPhotos = sharedPhotos.filter((elem) => elem !== item.Key)
    }
    if (sharedPhotos.length > 0) {
      setIsSelected("IconBox_show");
      setShowAllCheckbox("showAll");
    } else {
      setShowAllCheckbox("");
    }
  }

  const handleSelectShare = (e, key, index) => {
    const { name, checked } = e.target;
    console.log(name, checked);

    const updateCheckbox = photos.map((Checkbox) => Checkbox.id === index ? { ...Checkbox, isSelected: checked } : Checkbox);
    setPhotos(updateCheckbox);
    if (checked) {
      sharedPhotos.push(key);
    } else {
      sharedPhotos = sharedPhotos.filter((elem) => elem !== key)
      if (photos.filter((elem) => elem.isSelected).map((elem) => { return elem.Key }).length > 1) {
      } else {
        setIsSelected("");
        setShowAllCheckbox("");
      }
    }
  }

  const handleSelectAll = () => {
    sharedPhotos = photos.map((elem) => { return elem.Key });
    console.log("sharedPhotos", photos);
    const updateCheckbox = photos.map((Checkbox) => { return { ...Checkbox, isSelected: true } });
    setPhotos(updateCheckbox);
  };
  const handleUnselectAll = () => {
    const updateCheckbox = photos.map((Checkbox) => { return { ...Checkbox, isSelected: false } });
    setPhotos(updateCheckbox);
    setIsSelected("");
    setShowAllCheckbox("");
  };

  const handleDownloadSelected = async (e) => {
    toast.success("Zip with all selected images will be downloaded sortally...", {
      autoClose: 1000,
      transition: Slide,
    });
    const zip = new JSZip();

    let sharedPhotos = photos.filter((elem) => elem.isSelected).map((elem) => { return { Key: elem.Key, imageName: elem.Key.split('/')[elem.Key.split('/').length - 1] } });

    for (let i = 0; i < sharedPhotos.length; i++) {
      const element = sharedPhotos[i];
      const slideParams = { Bucket: bucketName, Key: element.Key };
      ;

      const response = await fetch(s3.getSignedUrl('getObject', slideParams));
      const blob = await response.blob();
      // const blob = await new Blob([response], { type: 'application/image' });
      zip.file(element.imageName, blob);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Download the zip file
    const zipFileName = 'downloaded_images.zip';
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = zipFileName;
    downloadLink.click();
  }

  const handleCopy = async () => {
    let userId = user.uid;

    let sharedPhotos = photos.filter((elem) => elem.isSelected).map((elem) => { return elem.Key });
    let value = {
      userID: userId,
      images: sharedPhotos,
    }
    let linkId = UuidGenerater()
    if (sharedPhotos.length === 0) {
      toast.error("No selected photos to share.", {
        autoClose: 1000,
        transition: Slide,
      });
      return;
    }
    const ref = doc(db, "sharedLinks", linkId);
    await setDoc(ref, value).then(() => {
      navigator.clipboard.writeText(process.env.REACT_APP_URL + "media/share/" + linkId)
      toast.success("Link Copied Successfully.", {
        autoClose: 1000,
        transition: Slide,
      });
    }).catch((error) => {
      toast.error(error.message, {
        autoClose: 1000,
        transition: Slide,
      });
    });

  }

  const handleWhatsapp = async () => {
    let userId = user.uid;
    let sharedPhotos = photos.filter((elem) => elem.isSelected).map((elem) => { return elem.Key });

    let value = {
      userID: userId,
      images: sharedPhotos,
    }
    let linkId = UuidGenerater()

    if (sharedPhotos.length === 0) {
      toast.error("No selected photos to share.", {
        autoClose: 1000,
        transition: Slide,
      });
      return;
    }

    const ref = doc(db, "sharedLinks", linkId);
    await setDoc(ref, value).then(() => {
      const encodedText = encodeURIComponent("My Photos");
      const encodedUrl = encodeURIComponent(process.env.REACT_APP_URL + "media/share/" + linkId);
      const whatsappUrl = `https://wa.me/?text=${encodedText}%20${encodedUrl}`;
      window.open(whatsappUrl, '_blank');
    }).catch((error) => {
      toast.error(error.message, {
        autoClose: 1000,
        transition: Slide,
      });
    });


  }

  const handleTelegram = async () => {
    let userId = user.uid;
    let sharedPhotos = photos.filter((elem) => elem.isSelected).map((elem) => { return elem.Key });

    let value = {
      userID: userId,
      images: sharedPhotos,
    }
    let linkId = UuidGenerater()

    if (sharedPhotos.length === 0) {
      toast.error("No selected photos to share.", {
        autoClose: 1000,
        transition: Slide,
      });
      return;
    }
    const ref = doc(db, "sharedLinks", linkId);
    await setDoc(ref, value).then(() => {
      const encodedText = encodeURIComponent("My Photos");
      const encodedUrl = encodeURIComponent(process.env.REACT_APP_URL + "media/share/" + linkId);
      const telegramUrl = `https://t.me/share/url?url=${encodedUrl}&text=${encodedText}`;
      window.open(telegramUrl, '_blank');
    }).catch((error) => {
      toast.error(error.message, {
        autoClose: 1000,
        transition: Slide,
      });
    });
  }

  const handleLongPress = (item, index) => {
    handleLongSelectShare(item, index);
  };

  const handleTempClick = (index) => {
    setSlideIndex(index)
  };


  return (
    user ?
      (
        <>
          <div className='mx-2 md:mx-5  mt-5 min-h-screen scroll-auto'>
            {data ? (

              photos && photos.length > 0 ? (
                <>
                  <Masonry
                    breakpointCols={
                      {
                        // Number of columns by default
                        default: 4,
                        // Number of columns for screens 1100px and above
                        1100: 3,
                        // Number of columns for screens 700px and above
                        700: 2
                      }}
                    className="my-masonry-grid gap-1 md:gap-3"
                    columnClassName="my-masonry-grid_column"
                  >

                    {photos.map((items, index) => {
                      return (
                        <>
                          <div key={items.Key} className={``}>
                            <Checkbox
                              className={`checkBox gridCheckbox  absolute l-6 t-6 h-6 w-6 text-[#FF512F] rounded cursor-pointer ${showAllCheckbox}`}
                              style={{ zIndex: 1 }}
                              id={items.Key}
                              name={items.Key}
                              onChange={(e) => handleSelectShare(e, items.Key, index)}
                              checked={items.isSelected}
                            // defaultChecked={items.isSelected}
                            />

                            <LongPressButton
                              key={index}
                              handleLongPress={handleLongPress}
                              handleTempClick={handleTempClick}
                              item={items}
                              index={index}
                            >
                              <img src={items.url} alt={`${items.imageName}`} className='object-cover w-full h-full rounded-md img-preview' />
                            </LongPressButton>
                          </div >

                          {isModalOpen && (imgKey === items.Key && <Model onClose={closeModal} targetPhoto={items.Key} s3_sdk={s3} bucket={bucketName} tempPhotoIndex={index} allTempPhotos={photos} />)
                          }
                        </>
                      )

                    })}
                  </Masonry>

                </>
              ) :

                videos && videos.length > 0 && (
                  <Masonry
                    breakpointCols={
                      {
                        // Number of columns by default
                        default: 3,
                        // Number of columns for screens 1100px and above
                        1100: 2,
                        // Number of columns for screens 700px and above
                        700: 1
                      }}
                    className="my-masonry-grid gap-1"
                    columnClassName="my-masonry-grid_column"
                  >
                    {
                      videos.map((i, index) => {

                        const parts = i.Key.split('/')

                        // Find the last dot (.) in the string
                        const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
                        let resultString
                        if (lastDotIndex !== -1) {
                          // Extract the part of the string before the last dot
                          resultString = parts[parts.length - 1].substring(0, lastDotIndex);
                          let arrayResultString = resultString.split('_');
                          resultString = arrayResultString[0];
                        } else {
                          // If there is no dot in the string, keep the original string
                          resultString = parts[parts.length - 1];
                          let arrayResultString = resultString.split('_');
                          resultString = arrayResultString[0];
                        }

                        return (
                          <div key={i.Key} className={`flex flex-wrap rounded-md mb-2 md:mb-5 mx-1 md:mx-2`}>

                            <video src={i.url} className='w-full h-auto' controls />
                            <h1 className='text-black text-sm font-medium '>{resultString}</h1>
                          </div>
                        )

                      })
                    }
                  </Masonry>
                )



            ) : <Loading />

            }


            <Lightbox
              on={{
                view: (index) => {
                  setSlideIndex(index.index);
                },
              }}
              index={slideIndex}
              slides={photos}
              open={slideIndex >= 0}
              close={() => setSlideIndex(-1)}
              render={{
                iconLoading: () =>
                  <img className='' src={loader} alt="Loading............" />,
                iconPrev: () =>
                  <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`} style={{ fontSize: "40px" }}>
                    <MdNavigateBefore />
                  </button>,
                iconNext: () =>
                  <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`} style={{ fontSize: "40px" }}>
                    <MdNavigateNext />
                  </button>,
                iconClose: () =>
                  <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}>
                    <AiFillCloseCircle />
                  </button>
                ,
              }}
              toolbar={{
                buttons: [
                  slideIndex >= 0 ?
                    <Checkbox
                      className="checkBox l-6 t-6 mt-3 mr-1.5 h-6 w-6 text-[#FF512F] rounded cursor-pointer "
                      style={{ zIndex: 1 }}
                      id={photos[slideIndex].Key}
                      name={photos[slideIndex].Key}
                      onChange={(e) => handleSelectShare(e, photos[slideIndex].Key, slideIndex)}
                      checked={photos[slideIndex].isSelected}
                    /> : "",
                  slideIndex >= 0 ?
                    <button
                      onClick={() => {
                        handleFavourite(photos[slideIndex].Key)
                      }}
                      className={`pr-2 text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}
                    >
                      {
                        photos[slideIndex].isFavouriteSlide === 1 ? <AiFillHeart /> : <AiOutlineHeart />
                      }
                    </button> : "",
                  <button
                    onClick={() => handleDownload(photos[slideIndex].downloadUrl, photos[slideIndex].Key)}
                    className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass}`}
                  >
                    < AiOutlineDownload />
                  </button>,
                  "close",
                ],
              }}
              plugins={[Zoom, Captions]}
            />

          </div >


          <div className='flex justify-center'>
            <div className={`fixed right-0 p-2 md:p-4 bg-black rounded-md bg-opacity-50 z-10 mb-2 md:mb-5 IconBox ${isSelected}`} style={{ zIndex: 1 }}>
              <div className=''>
                {photos && photos.length > 0 ? (
                  <>
                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass} mb-2 btn-grad`} onClick={(e) => handleSelectAll(e)}>
                      <FaCheckCircle />
                    </button>
                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass} mb-2 btn-grad`} onClick={(e) => handleUnselectAll(e)}>
                      <ImRadioUnchecked />
                    </button>
                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass} mb-2 btn-grad`} onClick={(e) => handleDownloadSelected(e)}>
                      <FaDownload />
                    </button>
                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass} mb-2 btn-grad`} onClick={(e) => handleCopy(e)}>
                      <FaCopy />
                    </button>
                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass} mb-2 btn-grad`} onClick={(e) => handleWhatsapp(e)}>
                      <IoLogoWhatsapp />
                    </button>
                    <button className={`text-5xl/ text-[#FF512F] icons-size ${buttonClass} btn-grad`} onClick={(e) => handleTelegram(e)} >
                      <FaTelegramPlane />
                    </button>
                  </>
                ) : ""}
              </div>
            </div>
          </div>
          <div className='flex justify-center'>
            <div className="fixed bottom-0 p-2 md:p-4 bg-black rounded-md bg-opacity-50 z-10 mb-2 md:mb-5 " style={{ zIndex: 1 }}>
              <div className='flex justify-center space-x-3'>

                <ReactPaginate
                  previousLabel={"<<"}
                  nextLabel={">>"}
                  breakLabel={"•••"}
                  pageCount={totalCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange}
                  containerClassName={"flex flex-row justify-center space-x-3"}
                  pageClassName={"btn-grad"}
                  pageLinkClassName={""}
                  previousClassName={"font-bold text-lg text-white"}
                  previousLinkClassName={"font-bold text-lg"}
                  nextClassName={"font-bold text-lg text-white"}
                  nextLinkClassName={"font-bold text-lg"}
                  breakClassName={"space-x-2"}
                  breakLinkClassName={"text-white"}
                  activeClassName={"border border-2 border-white"}
                />
              </div>
            </div>
          </div>

          <ToastContainer />
        </>) : (
        <div className='text-center mt-12 md:my-40 text-6xl font-bold text-black min-h-screen'>
          Please Log In! </div>
      )
  )
}
