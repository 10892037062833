
import { Footer } from 'flowbite-react';
import { BsFacebook,  BsInstagram, BsTwitter } from 'react-icons/bs';
import LogoImage from "../Assets/images/logo.png"
import { Link } from 'react-router-dom';

export default function FooterWeb() {
  return (
    <Footer container className='bg-opacity-90 bg-gray-200 rounded-none mb-0 bg-opacity-80'>
      <div className="w-full">
        <div className="lg:flex justify-around items-center">
          
          <div className='logoSection'>
            <Footer.Brand alt="logo" className='footer_logo' href="#" name="" src={LogoImage} />
          </div>
          <div className="options">
            <div>
              <Footer.Title title="Gallery" />
              <Footer.LinkGroup col>
                <Footer.Link >
                  <Link to='/media'>Media </Link>
                </Footer.Link>
                
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Follow us" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">
                  Facebook
                </Footer.Link>
                <Footer.Link href="#">
                  Instagram
                </Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Contact" />
              <Footer.LinkGroup col>
                <Footer.Link href="#">
                  Contact Us
                </Footer.Link>

              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        {/* <div className=''><Footer.Divider className='hidden md:block'/></div> */}
        
        <div className="copyright text-center py-4">
          <Footer.Copyright
            by="digiAlbum"
            href="/"
            year={2023}
          />
          <div className="mt-4 md:mt-4 flex space-x-6 sm:mt-0 justify-center">
            <Footer.Icon
              href="#"
              icon={BsFacebook}
            />
            <Footer.Icon
              href="#"
              icon={BsInstagram}
            />
            <Footer.Icon
              href="#"
              icon={BsTwitter}
            />

          </div>
        </div>
      </div>
    </Footer>
  )
}


