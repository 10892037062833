import React, { useState } from 'react';
import Image from '../Assets/images/1white-logo.png';
import Slider from "react-slick";
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { FaUser, FaLock } from "react-icons/fa";
import LogoImage from "../Assets/images/logo.png"


export default function HeaderCard() {
  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [showLoginForm, setShowLoginForm] = useState(true)

  const navigate = useNavigate();
  const { signIn, user } = UserAuth()

  const [resetEmailSent, setResetEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      await signIn(email, password)
      navigate('/media')
    }
    catch (e) {
      toast.error(e.message
        , {
          position: toast.POSITION.TOP_CENTER
        });
      // console.log(JSON.parse(JSON.stringify(e.message)))
    }

  }


  const handleForgetPassword = async (e) => {
    e.preventDefault()
    try {
      console.log("dafadfadf", "email");
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      toast.success("Password reset email sent. Check your inbox.", {
        autoClose: 1000,
        transition: Slide,
      });
    } catch (error) {
      console.log("error", error.message);
      toast.error(error.message, {
        autoClose: 1000,
        transition: Slide,
      });
    }
  };



  return (
    <>
      <div className=''>

        {/* card */}
        <div className='Slider_section w-100'>

          <div className='slider_set'>

            <div className="slider_content text-white head-txt">
              <div className='slider_box'>
                <img src={Image} className='header_logo' />
              </div>
              <div className="slider_box text-[6px] md:text-lg font-semibold md:font-bold">
                <span className='typing-animation animate-typing animate-reacting line-clamp-1'>Your Love Story, Always</span>
                <span className='typing-animation animate-typing animate-reacting line-clamp-1'> Accessible, Forever Unforgettable!</span>
              </div>
            </div>
            {
              user ? (
                <div className='welcome_text'>
                  <div className='w-100'>
                    <p className='text-sm md:text-3xl font-bold text-center my-2 md:my-4'>Welcome to digiAlbum</p>
                    <p className='text-xs md:text-base text-center'>Enjoy Your Amazing Photos and Videos</p>
                    <p className='text-sm md:text-3xl font-bold text-center my-2 md:my-4'>
                      <Link to='/media' className="btn btn-grad text-white m-2 md:mt-4 focus:outline-none font-medium rounded-md md:rounded-lg text-xs md:text-sm px-2 md:px-5 py-1.5 md:py-2.5 mb-2 ">See Media</Link>
                    </p>
                  </div>
                </div>
              ) : (
                <p className='text-sm md:text-3xl font-bold text-center my-2 md:my-4'>
                  <p style={{ color: "white" }}>Contact us to Create your Account </p>
                  <p style={{ color: "white" }}>OR</p>
                  <Link onClick={() => setOpen(true)} className="btn btn-grad text-white m-2 md:mt-4 focus:outline-none font-medium rounded-md md:rounded-lg text-xs md:text-sm px-2 md:px-5 py-1.5 md:py-2.5 mb-2 ">Log In</Link>
                </p>
              )
            }

          </div>

        </div>


      </div>

      <ToastContainer />
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="login_fragment"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" />
          </Transition.Child>


          <div className="fixed inset-0 z-10 w-screen overflow-y-auto neumorphism">
            <div className="flex set_modual items-end justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="content">
                  <div className='image_logo'>
                    <img src={LogoImage} alt="logo" className='nav_logo h-12 md:h-10 w-auto' />
                  </div>
                  <div className="text"></div>
                  {showLoginForm ? (
                    <div className='login_form'>
                      <form onSubmit={handleSubmit}>

                        <div className="mb-6 md:mb-6 field">
                          <span class="fa fa-user">
                            <FaUser />
                          </span>
                          <input type="email" id="email" required onChange={(e) => setEmail(e.target.value)} placeholder="name@mail.com" />
                        </div>

                        <div className="mb-6 md:mb-6 field">
                          <span class="fa fa-user">
                            <FaLock />
                          </span>
                          <input type="password" id="password" required onChange={(e) => setPassword(e.target.value)} placeholder="Your Password" />
                        </div>
                        <button type="submit">Submit</button>

                      </form>
                      <div class="icon-button float-right" >
                        <span onClick={() => setShowLoginForm(false)}><i class="fa fa-google"></i>  Forgot Password?</span>
                      </div>
                    </div>
                  ) : (
                    <div className='login_form forget_form'>
                      <form onSubmit={handleForgetPassword}>
                        <div className="mb-6 md:mb-6 field">
                          <span class="fa fa-user">
                            <FaUser />
                          </span>
                          <input type="email" id="email" required onChange={(e) => setEmail(e.target.value)} placeholder="name@mail.com" />
                        </div>
                        <button type="submit">Submit</button>
                      </form>
                      <div class="icon-button float-right" >
                        <span onClick={() => setShowLoginForm(true)}><i class="fa fa-google"></i>  Log In</span>
                      </div>
                    </div>
                  )}



                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </>

  )
}


