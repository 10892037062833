import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updatePassword,
} from "firebase/auth";
import { useMatch, useNavigate } from "react-router-dom";

import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";

import { auth } from "../firebase";

// creating a context for the user
const userContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [Link, setLink] = useState(null);
  const [sharedLink, setSharedLink] = useState(null);

  const navigate = useNavigate();

  // handleing sign in requests using firebase auth
  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const setPassword = (newPassword) => {
    updatePassword(auth.currentUser, newPassword);
  };

  const db_read = async () => {
    if (user) {
      const q = query(
        collection(db, "gallery"),
        where("userID", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot?.forEach(async (doc) => {
        const data = await JSON.parse(JSON.stringify(doc.data()));
        setLink(data);
      });
    }
  };

  const db_shared_read = async () => {
    const q = query(
      collection(db, "sharedLinks"),
      where("userID", "==", user.uid)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot?.forEach(async (doc) => {
      const data = await JSON.parse(JSON.stringify(doc.data()));
      setSharedLink(data);
    });
  };

  const addToFvtPhotos = async (photoUrl) => {
    console.log("fvt to add: ", photoUrl);
    console.log(Link.FavoritePhotos);

    const ref = doc(db, "gallery", user.uid);
    await updateDoc(ref, {
      FavoritePhotos: arrayUnion(photoUrl),
    });
  };

  const remFvtPhoto = async (urlToRemove) => {
    const ref = doc(db, "gallery", user.uid);
    let currentFvtPhotos = Link.FavoritePhotos;
    console.log("current ", currentFvtPhotos);

    const updatedFvtPhotos = currentFvtPhotos.filter(
      (photoUrl) => photoUrl !== urlToRemove
    );

    await updateDoc(ref, {
      FavoritePhotos: updatedFvtPhotos,
    });
  };

  const UuidGenerater = () => {
    let dt = new Date().getTime();
    let uuid = "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  useEffect(() => {
    const getCurrentUser = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      getCurrentUser();
    };
  }, []);

  useEffect(() => {
    user?.uid && db_read();
  }, [user]);

  return (
    <userContext.Provider
      value={{
        signIn,
        user,
        Link,
        sharedLink,
        logout,
        db_read,
        db_shared_read,
        setPassword,
        addToFvtPhotos,
        remFvtPhoto,
        UuidGenerater,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(userContext);
};
