import React, { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { IoIosFolder } from "react-icons/io";
import { MdFolderShared } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Img from "../Assets/images/media-img.jpg";
import { AiOutlineDownload } from "react-icons/ai";
import AWS from "aws-sdk";
import { Button, Tooltip } from "flowbite-react";

export const Media = () => {
  const { user, Link, db_read } = UserAuth();
  let folders = Link && Object.keys(Link);
  const [finalfolders, setFinalFolders] = useState([]);
  let TempData = Link;

  let specialFolder = [];
  const navigate = useNavigate();
  const photos = Link && Link.FolderPhotos;

  const wasabi_key_id = process.env.REACT_APP_WASABI_ACCESS_KEY_ID;
  const wasabi_key = process.env.REACT_APP_WASABI_ACCESS_KEY;
  const bucketName = "digialbum";
  const region = "ap-southeast-1"; // Update with your bucket's region
  const endpoint = "https://s3.ap-southeast-1.wasabisys.com"; // Update with your Wasabi endpoint
  // const folderName = "Personal/Birthday/Photos/";

  // wasabi credentials
  const s3 = new AWS.S3({
    region: region,
    endpoint: endpoint,
    credentials: {
      accessKeyId: wasabi_key_id,
      secretAccessKey: wasabi_key,
    },
  });
  AWS.config.update({
    region: region,
    accessKeyId: wasabi_key_id,
    secretAccessKey: wasabi_key,
  });

  if (folders) {

    specialFolder = folders.filter(
      (elem) =>
        elem.toLowerCase() === "favoritephotos" ||
        elem.toLowerCase() === "albumphotos"
    );

    folders = folders.filter(
      (elem) =>
        elem.toLowerCase().indexOf("folderphotos") &&
        elem.toLowerCase().indexOf("favoritephotos") &&
        elem.toLowerCase().indexOf("albumphotos") &&
        (elem.toLowerCase().indexOf("photos") > 0 ||
          elem.toLowerCase().indexOf("videos") > 0)
    );
  }

  const getData = async (datas) => {
    const promise = datas.map(async (path) => {
      const params = {
        Bucket: bucketName,
        MaxKeys: 2,
        Prefix: TempData[path],
        ContinuationToken: null,
      };
      return await s3.listObjectsV2(params).promise();
    });

    Promise.all(promise)
      .then((urls) => {
        let tempFolders = folders;
        for (let index = 0; index < urls.length; index++) {
          if (urls[index].Contents.length === 1) {
            folders = folders
              .slice(0, index)
              .concat(tempFolders.slice(index + 1));
          }
        }
        setFinalFolders(folders);
      })
      .catch((error) => {
        console.error("Error generating signed URLs:", error.message);
      });
  };
  useEffect(() => {
    if (finalfolders.length === 0) {
      folders && getData(folders);
    }
  }, [folders, finalfolders]);

  const handleClick = (e, targetFolder) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    const propsToPass = {
      target: targetFolder,
    };
    if (targetFolder.toLowerCase() === "favoritephotos") {
      navigate("/media/fvtphotos", { state: propsToPass });
    } else if (targetFolder.toLowerCase() === "albumphotos") {
      navigate("/media/albumPhotos", { state: propsToPass });
    } else {
      navigate("/media/target", { state: propsToPass });
    }
  };
  useEffect(() => {
    db_read();
  }, []);

  const downloadFolder = (e, target) => {
    e.preventDefault();

    const params = {
      Bucket: bucketName,
      Key: target,
      Expires: 3600, // 1 hour (in seconds)
    };
    const signedUrl = s3.getSignedUrl("getObject", params);

    window.location.href = signedUrl;
  };
  return (
    <div>
      {user ? (
        finalfolders.length > 0 ? (
          <>
            <div className="min-h-screen flex flex-col flex-wrap md:flex-row md:justify-center mt-5 md:mt-0 is-center gap-5 mb-10 folder-grid">
              {finalfolders.map((i, index) => {
                return (
                  // <div key={index}>

                  //      <button onClick={(e)=>handleClick(e,i)} className='flex flex-col items-center mb-5'>
                  //             <MdFolderShared className='text-xl md:text-6xl text-[#f4f444]'/>
                  //             <span className='text-lg md:text-2xl font-semibold text-white'>{ i }</span>
                  //     </button>

                  // </div>

                  <div
                    key={index}
                    className={`col-span-full md:grid-start-${index} md:col-span-1 mt-5`}
                  >
                    <div class="max-w-xs bg-gray-200 bg-opacity-90 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 neuromorphic zoom-trans">
                      {/* Image */}
                      <div className="max-h-xs">
                        <buton onClick={(e) => handleClick(e, i)}>
                          <img
                            class="rounded-t-lg"
                            src={photos[`${i}`] ? photos[`${i}`] : Img}
                            alt=""
                          />
                        </buton>
                      </div>
                      {/* Title and Description */}
                      <div class="p-2 md:p-5 flex flex-row">
                        <button
                          onClick={(e) => handleClick(e, i)}
                          className="flex flex-col items-center mb-5"
                        >
                          <span className="text-lg md:text-2xl font-semibold text-black">
                            {i}
                          </span>
                        </button>
                        {Link.DownloadFolder[`${i}`] && (
                          // <Tooltip content="Donload all">
                          <Button
                            onClick={(e) =>
                              downloadFolder(e, Link.DownloadFolder[`${i}`])
                            }
                            className="ml-4"
                          >
                            <AiOutlineDownload className="" />
                          </Button>
                          // </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
            <div className="min-h-screen flex flex-col flex-wrap md:flex-row md:justify-center mt-5 md:mt-0 is-center gap-5 mb-10 folder-grid">
              {specialFolder.map((i, index) => {
                return (
                  <div
                    key={index}
                    className={`col-span-full md:grid-start-${index} md:col-span-1 mt-5`}
                  >
                    <div class="max-w-xs bg-gray-200 bg-opacity-90 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 neuromorphic zoom-trans">
                      {/* Image */}
                      <div className="max-h-xs">
                        <buton onClick={(e) => handleClick(e, i)}>
                          <img
                            class="rounded-t-lg"
                            src={photos[`${i}`] ? photos[`${i}`] : Img}
                            alt=""
                          />
                        </buton>
                      </div>
                      {/* Title and Description */}
                      <div class="p-2 md:p-5 flex flex-row">
                        <button
                          onClick={(e) => handleClick(e, i)}
                          className="flex flex-col items-center mb-5"
                        >
                          <span className="text-lg md:text-2xl font-semibold text-black">
                            {i}
                          </span>
                        </button>
                        {Link.DownloadFolder[`${i}`] && (
                          // <Tooltip content="Donload all">
                          <Button
                            onClick={(e) =>
                              downloadFolder(e, Link.DownloadFolder[`${i}`])
                            }
                            className="ml-4"
                          >
                            <AiOutlineDownload className="" />
                          </Button>
                          // </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <Loading />
        )
      ) : (
        <div className="text-center mt-12 md:my-40 text-6xl font-bold text-black min-h-screen">
          Please Log In!
        </div>
      )}
    </div>
  );
};
