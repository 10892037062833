import React, { useState } from 'react'
import { Navbar } from 'flowbite-react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import LogoImage from "../Assets/images/logo.png"
import { Slide, ToastContainer, toast } from 'react-toastify';

import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { FaUser, FaLock } from "react-icons/fa";

const NavbarDefault = () => {

  const [open, setOpen] = useState(false)

  const cancelButtonRef = useRef(null)

  const [mobileOpen, setMobileOpen] = useState(false)

  const { signIn, user, logout } = UserAuth()
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true)

  const navigate = useNavigate()



  const handleClick = async () => {
    try {
      await logout();
      navigate('/')
    }
    catch (e) {
      console.log(e)
    }
  }

  const handleMobileMenu = () => {
    setMobileOpen(!mobileOpen);  //toggle the state of mobile menu
  }

  const closeMobileMenu = () => {
    setMobileOpen(false)
  }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault()

    setOpen(false);
    try {
      await signIn(email, password)
      navigate('/media')
    }
    catch (e) {
      toast.error(e.message
        , {
          position: toast.POSITION.TOP_CENTER
        });
      // console.log(JSON.parse(JSON.stringify(e.message)))
    }

  }


  const handleForgetPassword = async (e) => {
    e.preventDefault()
    try {
      console.log("dafadfadf", "email");
      await sendPasswordResetEmail(auth, email);
      setResetEmailSent(true);
      toast.success("Password reset email sent. Check your inbox.", {
        autoClose: 1000,
        transition: Slide,
      });
    } catch (error) {
      console.log("error", error.message);
      toast.error(error.message, {
        autoClose: 1000,
        transition: Slide,
      });
    }
  };
  return (
    <>
      <Navbar fluid className='Navbar_set bg-opacity-90 bg-gray-200 sticky top-0 z-50'>
        <Navbar.Brand>
          <button onClick={() => navigate("/")}>
            <span className="self-center whitespace-nowrap flex flex-row">
              <img src={LogoImage} alt="logo" className='nav_logo h-12 md:h-10 w-auto' />
            </span>
          </button>
        </Navbar.Brand>

        <Navbar.Toggle onClick={handleMobileMenu} />

        <Navbar.Collapse className={`bg-white bg-opacity-90 text-black items-center md:bg-transparent ${mobileOpen ? 'block' : 'hidden'}`}>

          <Navbar.Link
            onClick={closeMobileMenu}
          >
            <p className='text-black md:text-gray-700'>
              <Link to='/'> Home</Link>
            </p>
          </Navbar.Link >

          <Navbar.Link onClick={closeMobileMenu} className=' text-black md:text-gray-700'>
            {user ? (
              <Link to='/media'>Media</Link>
            ) : (
              <Link onClick={() => setOpen(true)}>Media</Link>
            )
            }

          </Navbar.Link>


          <Navbar.Link onClick={closeMobileMenu} className='text-black md:text-gray-700'>
            <Link to='/contact'>Contact Us</Link>
          </Navbar.Link >


          {
            user ? (
              <div className='flex flex-row space-x-3'>
                <Navbar.Link onClick={closeMobileMenu} className=''>

                  <button type="button" className="btn-grad" onClick={handleClick}>Log Out</button>
                </Navbar.Link>
                <Navbar.Link onClick={closeMobileMenu} className=''>
                  <button type="button" className="btn-grad" onClick={() => navigate('/password_update')}>Update Password</button>
                </Navbar.Link>
              </div>
            ) : (
              <Navbar.Link onClick={closeMobileMenu} className=''>
                <button type="button" className="btn-grad" onClick={() => setOpen(true)}>Log In</button>
              </Navbar.Link>
            )
          }
        </Navbar.Collapse>

      </Navbar>


      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-90 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto neumorphism">
            <div className="flex set_modual items-end justify-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="content">
                  <div className='image_logo'>
                    <img src={LogoImage} alt="logo" className='nav_logo h-12 md:h-10 w-auto' />
                  </div>
                  <div className="text"></div>
                  {showLoginForm ? (
                    <div className='login_form'>
                      <form onSubmit={handleSubmit}>

                        <div className="mb-6 md:mb-6 field">
                          <span class="fa fa-user">
                            <FaUser />
                          </span>
                          <input type="email" id="email" required onChange={(e) => setEmail(e.target.value)} placeholder="name@mail.com" />
                        </div>

                        <div className="mb-6 md:mb-6 field">
                          <span class="fa fa-user">
                            <FaLock />
                          </span>
                          <input type="password" id="password" required onChange={(e) => setPassword(e.target.value)} placeholder="Your Password" />
                        </div>
                        <button type="submit">Submit</button>

                      </form>
                      <div class="icon-button float-right" >
                        <span onClick={() => setShowLoginForm(false)}><i class="fa fa-google"></i>  Forgot Password?</span>
                      </div>
                    </div>
                  ) : (
                    <div className='login_form forget_form'>
                      <form onSubmit={handleForgetPassword}>
                        <div className="mb-6 md:mb-6 field">
                          <span class="fa fa-user">
                            <FaUser />
                          </span>
                          <input type="email" id="email" required onChange={(e) => setEmail(e.target.value)} placeholder="name@mail.com" />
                        </div>
                        <button type="submit">Submit</button>
                      </form>
                      <div class="icon-button float-right" >
                        <span onClick={() => setShowLoginForm(true)}><i class="fa fa-google"></i>  Log In</span>
                      </div>
                    </div>
                  )}



                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>



  )
}

export default NavbarDefault