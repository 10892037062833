import React, { useEffect, useState } from 'react'
import HeaderCard from '../components/HeaderCard'
import Slider from "react-slick";
import { Cards } from '../components/Cards'
import { UserAuth } from '../context/AuthContext';
import AWS from 'aws-sdk';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';



export const HomePage = () => {

  const { user, Link, db_read } = UserAuth()
  const [photosUrls, setPhotosUrls] = useState([])
  const photos = Link && Link.FavoritePhotos


  // wasabi credentials
  const wasabi_key_id = process.env.REACT_APP_WASABI_ACCESS_KEY_ID
  const wasabi_key = process.env.REACT_APP_WASABI_ACCESS_KEY
  const bucketName = "digialbum";
  const region = "ap-southeast-1"; // Update with your bucket's region
  const endpoint = "https://s3.ap-southeast-1.wasabisys.com"; // Update with your Wasabi endpoint
  // const folderName = "Personal/Birthday/Photos/"; 

  // wasabi credentials
  const s3 = new AWS.S3({
    region: region,
    endpoint: endpoint,
    credentials: {
      accessKeyId: wasabi_key_id,
      secretAccessKey: wasabi_key
    }
  });
  AWS.config.update({
    region: region,
    accessKeyId: wasabi_key_id,
    secretAccessKey: wasabi_key,
  });

  const getPhotosUrls = async (link) => {

    const promise = link.slice(0, 5).map(async (path) => {
      const params = {
        Bucket: bucketName,
        Key: path,
      }

      return s3.getSignedUrlPromise('getObject', params)

    })

    // Wait for all signed URL promises to resolve
    Promise.all(promise)
      .then((urls) => {
        setPhotosUrls(urls);
      })
      .catch((error) => {
        console.error('Error generating signed URLs:', error.message);
      });
  }


  useEffect(() => {
    photos && getPhotosUrls(photos)
  }, [photos])

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button className={`text-5xl/ text-[#FF512F] icons-size pri_btn`} style={{}}
        onClick={onClick}>
        <MdNavigateBefore />
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <button className={`text-5xl/ text-[#FF512F] icons-size next_btn`} style={{}}
        onClick={onClick}>
        <MdNavigateNext />
      </button>
    );
  }

  var settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    initialSlide: 0,
    variableWidth: true,
    autoplay: true,
    adaptiveHeight: true,
    speed: 2000,
    autoplaySpeed: 2000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          variableWidth: true,
          centerMode: true,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div>
      <HeaderCard />
      <Slider {...settings} urlsArr={photosUrls}>
        {photosUrls.map((item, index) => {
          console.log("item", item)
          return (
            <div key={index} >
              <img src={item} alt='...' className='aspect-auto object-contain h-[250px] md:h-[450px] xs:h-[250px]' />
            </div>
          )

        })}
      </Slider>
      <Cards />
    </div>
  )
}
